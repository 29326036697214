import { parseLocale } from '../modules/shared/utils/language';

export let config: {
    GRAPH_REALTIME_ENDPOINT: string;
    GRAPH_ENDPOINT: string;
    REPORT_DOWNLOAD_ENDPOINT: string;
    DOCUMENTS_ENDPOINT: string;
    DOCUMENT_UPLOAD_ENDPOINT: string;
    DOCUMENT_UPDATE_UPLOAD_ENDPOINT: string;
    CASINO_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    SPORTS_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    SPORTS_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    SPORTS_CONFIG_PROVIDER_IMPORT_ENDPOINT: string;
    SPORTS_CONFIG_PROVIDER_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    CASINO_CONFIG_PROVIDERS_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_PROVIDERS_EXPORT_ENDPOINT: string;
    CASINO_CONFIG_GAMES_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_GAMES_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_PROVIDERS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_PROVIDERS_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_METHODS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_METHODS_EXPORT_ENDPOINT: string;
    REWARDS_BONUS_TEMPLATE_IMPORT_ENDPOINT: string;
    REWARDS_BONUS_TEMPLATE_EXPORT_ENDPOINT: string;
    REWARDS_FREE_BETS_TEMPLATE_IMPORT_ENDPOINT: string;
    REWARDS_FREE_BETS_TEMPLATE_EXPORT_ENDPOINT: string;
    REWARDS_FREE_SPINS_TEMPLATE_IMPORT_ENDPOINT: string;
    REWARDS_FREE_SPINS_TEMPLATE_EXPORT_ENDPOINT: string;
    NOTES_NOTE_CATEGORIES_IMPORT_ENDPOINT: string;
    NOTES_NOTE_CATEGORIES_EXPORT_ENDPOINT: string;
    TAGS_TAGS_IMPORT_ENDPOINT: string;
    TAGS_TAGS_EXPORT_ENDPOINT: string;
    TAGS_TAG_CATEGORIES_IMPORT_ENDPOINT: string;
    TAGS_TAG_CATEGORIES_EXPORT_ENDPOINT: string;
    AUTH_ENDPOINT: string;
    AUTH_CLIENT_ID: string;
    SEARCH_ENDPOINT: string;
    DEFAULT_LANGUAGE: string;
    DEFAULT_LOCALE: string;
    CMS_ASSET_URL: string;
    AVAILABLE_LANGUAGES: string[];
    AVAILABLE_LOCALES: string[];
    AVAILABLE_SELF_EXCLUSION_DURATIONS: ILimitDurationConfig[];
    AVAILABLE_TIMEOUT_DURATIONS: ILimitDurationConfig[];
    RESTRICTIONS_REASONS: [{ REASON: string; RESTRICTS: string[] }];
    JURISDICTION_CONFIG: { JURISDICTION: { AVAILABLE_SESSION_LIMITS: string[] } };
    ENVIRONMENT: string;
};

export type Config = typeof config;

export interface ILimitDurationConfig {
    MONTHS?: number;
    DAYS?: number;
    HOURS?: number;
    value: string;
}

export interface IJurisdictionsConfig {
    code: string;
    name: string;
}

export interface ICurrenciesConfig {
    code: string;
    name: string;
}

export interface Language {
    display_name: string;
    language_code: string;
    sort: number;
}

export const normalizeLocale = (str: string) => {
    return str.replace(/_/g, '-');
};

const BO_LANGUAGES_QUERY = `
    query BoLanguages {
        boLanguages {
            language_code
        }
    }`;

export const getBoLanguages = async (endpoint: string) => {
    const config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: BO_LANGUAGES_QUERY }),
    };

    try {
        const boLanguages = await fetch(endpoint, config);
        const languages = await boLanguages.json();
        return (
            languages.data?.boLanguages.map((lang: Language) =>
                normalizeLocale(lang.language_code),
            ) ?? []
        );
    } catch (error) {
        return [];
    }
};

export async function loadConfig(): Promise<void> {
    const request = await fetch('/config.json');
    const response = await request.json();
    const languages = await getBoLanguages(response.GRAPH_ENDPOINT);

    if (languages?.length) {
        const uniqueLanguages = new Set(
            languages.map((lang: string) => parseLocale(lang).language),
        );
        response.AVAILABLE_LOCALES = languages;
        response.AVAILABLE_LANGUAGES = Array.from(uniqueLanguages);
    }

    config = response;
}
